<template>
  <div class="html">
    <!-- قسمت جستجو -->
    <div class="container">
      <input type="search" placeholder="رویدادم را پیدا کن" />
    </div>

    <!-- نمایش ویدیو اصلی -->
    <div v-if="mainvideo" class="ted">
      <div class="img">
        <video
          :src="mainvideo.main_video_file"
          controls
          playsinline
          poster="@/assets/img/iri-1.jpg"
        ></video>
      </div>
      <div class="myTxt"></div>
      <div class="txt">
        <h1></h1>
        <div class="myTxt">
          <p>{{ mainvideo.title }}</p>
          <p>{{ mainvideo.description }}</p>
        </div>
      </div>
    </div>

    <!-- ویدیوها در دسته‌های مختلف -->
    <div class="events">
      <p>محبوب ترین سخنرانی ها</p>
      <div class="line"></div>
    </div>

    <div class="eventscontent">
      <div v-for="(videoTable, index) in videoTables" :key="index" class="card">
        <video
          class="videoTable"
          :src="videoTable.video_file"
          controls
          playsinline
        ></video>
        <div class="flex">
          <p>{{ videoTable.title }}</p>
          <div class="flex__2">
            <!-- لایک -->
            <font-awesome-icon
              class="item"
              :icon="['fas', 'heart']"
              @click="likeVideo(videoTable, index)"
            />
            <span>{{ videoTable.likes }}</span>

            <!-- بازدید -->
            <font-awesome-icon class="item" :icon="['fas', 'eye']" />
            <span>{{ videoTable.views }}</span>

            <!-- اشتراک گذاری -->
            <font-awesome-icon
              class="item"
              :icon="['fas', 'share-alt']"
              @click="shareVideo(videoTable)"
            />
          </div>
        </div>
        <p>{{ videoTable.description }}</p>
      </div>
    </div>

    <!-- نمایش سایر رویدادها و سخنران‌ها -->
    <div id="eventsContent" class="events">
      <p>رویداد ها</p>
      <div class="line"></div>
      <EventInformation />
    </div>

    <div class="events">
      <p>سخنران ها</p>
      <div class="line"></div>
      <div class="eventscontent">
        <div class="SpeakersCards">
          <img :src="spokesman1.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan1 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman2.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan2 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman3.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan3 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman4.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan4 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman5.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan5 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman6.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan6 />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SpokesMan1 from "@/components/Spoksman1to6/SpoksMan1.vue";
import SpokesMan2 from "@/components/Spoksman1to6/SpoksMan2.vue";
import SpokesMan3 from "@/components/Spoksman1to6/SpoksMan3.vue";
import SpokesMan4 from "@/components/Spoksman1to6/SpoksMan4.vue";
import SpokesMan5 from "@/components/Spoksman1to6/SpoksMan5.vue";
import SpokesMan6 from "@/components/Spoksman1to6/SpoksMan6.vue";
import EventInformation from "@/components/Content/EventInformation.vue";

export default {
  components: {
    SpokesMan1,
    SpokesMan2,
    SpokesMan3,
    SpokesMan4,
    SpokesMan5,
    SpokesMan6,
    EventInformation,
  },
  data() {
    return {
      spokesmen: [],
      spokesman1: { image: "" },
      spokesman2: { image: "" },
      spokesman3: { image: "" },
      spokesman4: { image: "" },
      spokesman5: { image: "" },
      spokesman6: { image: "" },
      mainvideo: {
        title: "",
        description: "",
        main_video_file: "",
        likes: 0,
        views: 0,
      },
      videoTables: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const response = await axios.get("/api");
        this.mainvideo = response.data.mainvideo;
        // دریافت ویدیوهای مختلف
        this.videoTables = [
          ...response.data.videotables.category1,
          ...response.data.videotables.category2,
          ...response.data.videotables.category3,
          ...response.data.videotables.category4,
          ...response.data.videotables.category5,
          ...response.data.videotables.category6,
        ];
        this.spokesmen = response.data.spokesman;
        this.spokesman1 = response.data.spokesman[0];
        this.spokesman2 = response.data.spokesman[1];
        this.spokesman3 = response.data.spokesman[2];
        this.spokesman4 = response.data.spokesman[3];
        this.spokesman5 = response.data.spokesman[4];
        this.spokesman6 = response.data.spokesman[5];

        // بازیابی لایک‌ها از localStorage
        const savedLikes = JSON.parse(localStorage.getItem("videoLikes")) || {};
        this.videoTables.forEach((videoTable, index) => {
          if (savedLikes[index]) {
            videoTable.likes = savedLikes[index];
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    // متد لایک ویدیو
    likeVideo(videoTable, index) {
      videoTable.likes++;

      // ذخیره لایک‌ها در localStorage
      const savedLikes = JSON.parse(localStorage.getItem("videoLikes")) || {};
      savedLikes[index] = videoTable.likes;
      localStorage.setItem("videoLikes", JSON.stringify(savedLikes));
    },
    shareVideo(videoTable) {
      const textArea = document.createElement("textarea");
      textArea.value = videoTable.video_file;
      document.body.appendChild(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        alert("لینک ویدیو کپی شد!");
      } catch (error) {
        console.error("خطا در کپی کردن لینک:", error);
      }

      document.body.removeChild(textArea);
    },
  },
};
</script>

<style scoped src="@/assets/css/content.css"></style>
