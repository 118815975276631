import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// تنظیم base URL برای Axios
axios.defaults.baseURL = "https://i.irirooydad.ir/"; // آدرس دامنه API شما

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faHeart, faEye, faShareAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faEye, faShareAlt, faHeart);

// ایجاد اپلیکیشن Vue و اضافه کردن axios به Vue
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.$axios = axios; // برای دسترسی آسان به axios در کامپوننت‌ها
app.mount("#app");
